import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import nxHamburger from '@/components/nx-hamburger';
import { mapGetters } from 'vuex';
import { AuthRule } from '@/api/updateUserInfo/users';
import bus from '@/views/layout/mixin/bus';
export default {
  data: function data() {
    return {
      activeName: 'second',
      activeIndex: '0',
      addrouters: [],
      addye: [],
      nav: [],
      imgurl: '',
      defaultArr: [],
      sidebaritem: {},
      staticId: 1,
      activeNames: '',
      color: 0,
      input: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'name', 'avatar', 'Msgid'])), {}, {
    onRoutes: function onRoutes() {
      var url = this.$route.path.replace('/', '').split('/');
      return url[url.length - 1];
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  components: {
    nxHamburger: nxHamburger
  },
  watch: {
    $route: function $route(to, from) {
      localStorage.setItem('paths', to.path);
      this.activeNames = '/' + to.path.split('/').splice(2, to.path.split('/').length).join('/');
      this.defaultArr = [this.activeNames.split('/').splice(0, 2).join('/'), this.activeNames.split('/').splice(0, 3).join('/'), this.activeNames];
    }
  },
  created: function created() {
    var _this = this;
    this.imgurl = require("../../assets/images/home/logo2.png");
    AuthRule().then(function (res) {
      _this.addye.push(_this.$store.getters.addRouters[0]);
      _this.addye.push(_this.$store.getters.addRouters[1]);
      _this.addye.push(_this.$store.getters.addRouters[2]);
      _this.addye.push(_this.$store.getters.addRouters[3]);
    });
    localStorage.setItem('paths', '/homePage/hoemLock');
    this.addrouters = this.addye;
    var obj = this.addrouters;
    if (obj.length > 0) {
      if (obj[0].children.length > 0) {
        var _path = localStorage.getItem('paths') ? localStorage.getItem('paths') : obj[0].path + '/' + obj[0].children[0].path;
        this.$router.replace(_path);
        if (obj[0].children[0].children) {
          _path = localStorage.getItem('paths') ? localStorage.getItem('paths') : obj[0].path + '/' + obj[0].children[0].path + '/' + obj[0].children[0].children[0].path;
          this.$router.replace(_path);
        }
      }
    }
    this.activeIndex = localStorage.getItem('activeIndexs');
    localStorage.setItem('datas', JSON.stringify(this.addrouters[this.activeIndex]));
    var sss = localStorage.getItem('paths');
    var path = String(sss);
    this.activeNames = '/' + path.split('/').splice(2, path.split('/').length).join('/');
    this.defaultArr = [this.activeNames.split('/').splice(0, 2).join('/'), this.activeNames.split('/').splice(0, 3).join('/'), this.activeNames];
  },
  methods: {
    aaa: function aaa(e, i) {
      this.$emit('myFun', e);
      this.color = i;
      localStorage.setItem('datas', JSON.stringify(e));
      this.sidebaritem = e;
      localStorage.setItem('activeIndexs', i);
      this.$emit('activeName', this.activeName);
    },
    handleSelect: function handleSelect(key, keyPath) {
      localStorage.setItem('NavBasics', key);
    },
    handleClick: function handleClick(tab, event) {},
    aaaa: function aaaa(val) {},
    toggleSideBar: function toggleSideBar() {
      bus.$emit('collapse', this.sidebar.opened);
      this.$store.dispatch('ToggleSideBar');
    },
    Tow: function Tow(item) {
      this.activeNames = item;
    },
    Three: function Three(item, hierarchy) {
      // console.log(hierarchy);
      if (hierarchy == 3) {
        this.activeNames = item;
      } else {
        this.activeNames = item;
      }
      // if (threeItem) {
      //     this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path + '/' + threeItem.path);
      //     localStorage.setItem('path', this.sidebaritem.path + '/' + item.path + '/' + subItem.path + '/' + threeItem.path);
      // } else {
      //     this.$router.replace(this.sidebaritem.path + '/' + item.path + '/' + subItem.path);
      //     localStorage.setItem('path', this.sidebaritem.path + '/' + item.path + '/' + subItem.path);
      // }
    }
  },
  mounted: function mounted() {
    // AuthRule().then(res => {

    //     res.data.forEach(item => {
    //       if (item.pid == 0) {
    //         this.nav.push(item)
    //       }
    //     });
    //     localStorage.setItem('datas', JSON.stringify(this.nav))
    this.sidebaritem = JSON.parse(localStorage.getItem('datas'));
    // })
  }
};