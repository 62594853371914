var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticStyle: { height: "100vh" } },
        [
          _c(
            "el-aside",
            {
              staticClass: "sidebar",
              staticStyle: { "background-color": "#1a95ff", padding: "0" },
              attrs: { width: "200px" },
            },
            [
              _c("div", [_c("h1", [_vm._v("123")])]),
              _vm._v(" "),
              _vm.staticId == 1
                ? _c(
                    "el-menu",
                    {
                      staticClass: "sidebar-el-menu",
                      attrs: {
                        "default-active": _vm.onRoutes,
                        "default-openeds": _vm.defaultArr,
                        collapse: _vm.isCollapse,
                        "background-color": "#324157",
                        "text-color": "#bfcbd9",
                        "unique-opened": "",
                        router: "",
                      },
                    },
                    [
                      _vm._l(_vm.sidebaritem.children, function (item) {
                        return [
                          item.children
                            ? [
                                _c(
                                  "el-submenu",
                                  {
                                    key: item.index,
                                    attrs: {
                                      index: "/" + item.path,
                                      route: { path: "/" + item.path },
                                    },
                                  },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _c(
                                        "div",
                                        {
                                          style:
                                            "/" + item.path == _vm.activeNames
                                              ? "background:#fff !important;color:#1a95ff;padding:0 20px;"
                                              : "padding:0 20px;",
                                        },
                                        [
                                          _c("i", { class: item.meta.icon }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              attrs: { slot: "title" },
                                              slot: "title",
                                            },
                                            [_vm._v(_vm._s(item.meta.title))]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(item.children, function (subItem) {
                                      return [
                                        subItem.children
                                          ? _c(
                                              "el-submenu",
                                              {
                                                key: subItem.index,
                                                attrs: {
                                                  route: {
                                                    path:
                                                      "/" +
                                                      item.path +
                                                      "/" +
                                                      subItem.path,
                                                  },
                                                  index:
                                                    "/" +
                                                    item.path +
                                                    "/" +
                                                    subItem.path,
                                                },
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "title" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        style:
                                                          "/" +
                                                            item.path +
                                                            "/" +
                                                            subItem.path ==
                                                          _vm.activeNames
                                                            ? "background:#fff !important;color:#1a95ff;padding:0 55px;"
                                                            : "padding:0 55px;",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            subItem.meta.title
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  subItem.children,
                                                  function (threeItem, i) {
                                                    return _c(
                                                      "el-menu-item",
                                                      {
                                                        key: i,
                                                        staticStyle: {
                                                          padding: "0px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.Three(
                                                              "/" +
                                                                item.path +
                                                                "/" +
                                                                subItem.path +
                                                                "/" +
                                                                threeItem.path,
                                                              3
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            style:
                                                              "/" +
                                                                item.path +
                                                                "/" +
                                                                subItem.path +
                                                                "/" +
                                                                threeItem.path ==
                                                              _vm.activeNames
                                                                ? "background:#fff !important;color:#1a95ff;padding:0 75px;"
                                                                : "padding:0 75px;",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                threeItem.meta
                                                                  .title
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          : _c(
                                              "el-menu-item",
                                              {
                                                key: subItem.index,
                                                staticStyle: { padding: "0px" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.Three(
                                                      "/" +
                                                        item.path +
                                                        "/" +
                                                        subItem.path,
                                                      2
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    style:
                                                      "/" +
                                                        item.path +
                                                        "/" +
                                                        subItem.path ==
                                                      _vm.activeNames
                                                        ? "background:#fff !important;color:#1a95ff;padding:0 55px;"
                                                        : "padding:0 55px;",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(subItem.meta.title)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            : [
                                _c(
                                  "el-menu-item",
                                  {
                                    key: item.path,
                                    on: {
                                      click: function ($event) {
                                        return _vm.Tow(item.path)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        style:
                                          item.path == _vm.activeNames
                                            ? "background:#fff !important; color:#1a95ff; padding:0 20px;"
                                            : "padding:0 20px;",
                                      },
                                      [
                                        _c("i", {
                                          class: item.meta.icon,
                                          style:
                                            item.path == _vm.activeNames
                                              ? "color:#1a95ff;"
                                              : "color:#fff;",
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: { slot: "title" },
                                            slot: "title",
                                          },
                                          [_vm._v(_vm._s(item.meta.title))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-container",
            [
              _c(
                "el-header",
                {
                  staticStyle: {
                    height: "50px",
                    "text-align": "right",
                    "font-size": "12px",
                  },
                },
                [
                  _c("nx-hamburger", {
                    staticClass: "hamburger-container",
                    attrs: {
                      toggleClick: _vm.toggleSideBar,
                      isActive: _vm.sidebar.opened,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "navse" },
                    [
                      _c(
                        "el-menu",
                        {
                          staticClass: "el-menu-demo",
                          staticStyle: {
                            height: "100%",
                            "border-bottom": "none",
                          },
                          attrs: {
                            "default-active": _vm.activeIndex,
                            mode: "horizontal",
                          },
                          on: { select: _vm.handleSelect },
                        },
                        _vm._l(_vm.addrouters, function (item, i) {
                          return _c(
                            "el-menu-item",
                            {
                              key: i,
                              staticStyle: {
                                float: "left",
                                height: "100%",
                                "line-height": "50px",
                              },
                              style: _vm.color == i ? "color:#409EFF;" : "",
                              attrs: { index: i + "" },
                              on: {
                                click: function ($event) {
                                  return _vm.aaa(item, i)
                                },
                              },
                            },
                            [
                              _c("i", { class: item.icon }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.name) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-main"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }